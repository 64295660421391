<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container-no-tarbar">
      <div class="public-area-block-box margin-bottom-40">
        <div class="public-areas public-area-tasks margin-bottom-10">
          <div class="public-area-tasks margin-bottom-10">
            <div class="weui-cells">
              <div class="weui-cell">
                <div class="weui-cell__bd">
                  <p class="public-block-title">
                    <i class="icon public-icon icon-public-shouzhi3 icon-2"></i
                    ><b class="text-red">本问卷可多次参与</b>: &nbsp;<a
                      class="text-blue text-underline btn-show-repeat"
                      href="javascript:;"
                      @click="showDialog"
                      >查看方法</a
                    >
                  </p>
                </div>
              </div>
              <div class="weui-cell">
                <div class="weui-cell__bd">
                  <p>
                    成功：<span class="text-red">限{{successLimit}}次</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;失败重试：<span class="text-red"
                      >不限次数</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="public-areas public-area-tasks margin-bottom-10">
          <div class="public-area-tasks margin-bottom-10">
            <div class="weui-cells">
              <div class="weui-cell">
                <div class="weui-cell__bd">
                  <p class="public-block-title">
                    <i class="icon public-icon icon-public-shouzhi3 icon-2"></i
                    ><b>本问卷作答指南</b>
                  </p>
                </div>
              </div>
              <div class="weui-cell">
                <div class="weui-cell__bd img-viewer-box answer-guide" v-html="answerGuide">
                  <!-- <p v-html="answerGuide"></p> -->
                  <!-- <p class=""><span style="text-wrap: nowrap;">特别注意以下几点：</span></p>
                  <p>如果遇到做完没有加积分的情况，请点击首页联系客服，加群私聊群主，按照要求做的都会补</p>
                  <p><span style="text-wrap: nowrap; color: rgb(0, 0, 0);"><br></span></p>
                  <p><span style="text-wrap: nowrap; color: rgb(0, 0, 0);">如果成功，一定要清理浏览器数据（密码可以不清理，方便直接答题），然后重新进入答题</span></p>
                  <p><span style="text-wrap: nowrap;"><br></span></p>
                  <p><span style="text-wrap: nowrap;">这个调查成功率90%以上，只要认真填写，二审一定能通过，点击右上角的...，复制链接到浏览器打开，更容易做</span></p>
                  <p><span style="text-wrap: nowrap;"><br></span></p>
                  <p><span style="color: rgb(255, 0, 0);"><strong><span style="text-wrap: nowrap;">不要每次都填一样的个人属性进入（比如：城市、性别、年龄、收入、工作、子女等情况，每次进入问卷，这些指标不能相同，必须得换），同一个内容的问卷，每人最多做5次，比如A问卷你做5次，就不要做了，换一个编号继续做，第二天刷到A问卷就可</span></strong><span style="text-wrap: nowrap;">以继续做</span></span></p>
                  <p><span style="text-wrap: nowrap; color: rgb(255, 0, 0);"><br></span></p>
                  <p><span style="text-wrap: nowrap; color: rgb(0, 0, 0);">一定要认真做，填空题10个字以上，查到乱做的封号处理</span><span style="text-wrap: nowrap; color: rgb(0, 32, 96);"></span></p>
                  <p><span style="text-wrap: nowrap;"><br></span></p>
                  <p><span style="text-wrap: nowrap;">城市选择自己所在省份的大城市，或者问卷给出的大城市</span></p>
                  <p><span style="text-wrap: nowrap;"><br></span></p>
                  <p><span style="text-wrap: nowrap;">男女皆可，年龄20-64岁，优先选择20-24岁、45-54岁、55-64岁，其中22-24岁单身/未婚，25-64岁已婚有小孩，小孩年龄等于自己的年龄减去22-24岁，学历大专或者本科，有工作</span></p>
                  <p><span style="text-wrap: nowrap;"><br></span></p>
                  <p><span style="text-wrap: nowrap;">您目前的就业状况如何？</span></p>
                  <p><span style="text-wrap: nowrap;">全职雇员（全日制雇员）</span></p>
                  <p><span style="text-wrap: nowrap;"><br></span></p>
                  <p><span style="text-wrap: nowrap;">请问您或您的家人有没有在以下行业工作的？</span></p>
                  <p><span style="text-wrap: nowrap;">以上均无</span></p>
                  <p><span style="text-wrap: nowrap;"><br></span></p>
                  <p><span style="text-wrap: nowrap;">请问您在过去半年中是否参加过任何形式的市场调查活动？</span></p>
                  <p><span style="text-wrap: nowrap;">没有</span></p>
                  <p><span style="text-wrap: nowrap;"><br></span></p>
                  <p><span style="text-wrap: nowrap;">请问以下哪一项最能代表您的家庭月总收入？</span></p>
                  <p><span style="text-wrap: nowrap;">15000元以上</span></p>
                  <p><br></p> -->






                  <!-- <p class="remark">
                    <b style="color: #c62930">条件一【重要！】：</b>
                  </p>
                  <br />
                  <p>请前后作答保持一致，否则甄别无效</p>

                  <p>
                    <br />
                    年龄：15-17岁（高中学生）；
                  </p>

                  <p>年龄：18-19岁 （大学生）；</p>

                  <p>注意作答内容和年龄符合，要符合逻辑常理！！</p>
                  <p class="remark">
                    <b style="color: #c62930">条件二【重要！】：</b>
                  </p>
                  <br />
                  <p>
                    1、您及家人工作行业：<span class="text-red"
                      >以上皆无！</span
                    >
                  </p>
                  <p>
                    2、本地居住年限：<span class="text-red">最长的时间</span>
                  </p>
                  <p>
                    3、最近是否参与过市场调查：<span class="text-red"
                      >没有参与过任何调查</span
                    >
                  </p>
                  <br />
                  <br />
                  <p class="remark"><b style="color: #c62930">基本规则：</b></p>
                  <br />
                  <p>1、请勿雷同作答，否则无效</p>
                  <p>2、选项之间、问题之间请勿作答矛盾，否则无效</p>
                  <p>3、开放题请认真填写，不要乱写乱敲，否则无效</p>
                  <br />
                  <br /> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="public-areas public-area-tasks margin-bottom-10">
          <div class="margin-bottom-10">
            <div class="weui-cells">
              <div class="weui-cell">
                <div class="weui-cell__bd">
                  <p class="public-block-title">
                    <i class="icon public-icon icon-public-money icon-2"></i
                    >成功作答记录
                  </p>
                </div>
              </div>
            </div>
            <div class="swiper" style="height: 300px; overflow: hidden">
              <!-- Additional required wrapper -->
              <div class="swiper-wrapper survey-success-record">
                <!-- Slides -->
                <div class="swiper-slide" data-swiper-autoplay="2000" v-for="(item, index) in finishedList " :key="index">
                  <div class="weui-cell">
                    <div class="weui-cell__hd">
                      <img
                        class="avatar"
                        :src="item.avatarUrl  || './assets/img/face.png'"
                      />
                    </div>
                    <div class="weui-cell__bd">
                      <span>{{item.nickName}}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span
                        class=""
                        >{{item.finishTime | timeFormat('MM-DD HH:mm:ss')}}</span
                      >
                    </div>
                    <div class="weui-cell__ft text-red">{{(item.bonus) / 100}}元</div>
                  </div>
                </div>
                <div class="swiper-slide" data-swiper-autoplay="2000" v-for="(item, index) in finishedList " :key="index + 'nnn'">
                  <div class="weui-cell">
                    <div class="weui-cell__hd">
                      <img
                        class="avatar"
                        :src="item.avatarUrl || './assets/img/face.png'"
                      />
                    </div>
                    <div class="weui-cell__bd">
                      <span>{{item.nickName}}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span
                        class=""
                        >{{item.finishTime | timeFormat('MM-DD HH:mm:ss')}}</span
                      >
                    </div>
                    <div class="weui-cell__ft text-red">{{(item.bonus) / 100}}元</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="public-area-submit-box public-areas public-survey-show-btn-box"
      >
        <div class="weui-cells margin-top-0">
          <div class="weui-cell">
            <div class="weui-cell__bd text-right">
              <a
                href="javascript:;"
                @click="share = true"
                class="
                  weui-btn weui-btn_primary
                  btn-go-answer-cl
                  show-btn-invite
                "
              >
                <i class="icon icon-public-weixin-p weixin"></i> 邀请好友
                <span class="layui-badge badge">1~2元/人</span>
              </a>

              <a
                href="javascript:;"
                @click="willAnswer"
                class="
                  weui-btn weui-btn_primary
                  btn-go-answer-cl
                  show-btn-invite
                "
              >
                <i class="icon icon-public-feedback write"></i> 开始答题
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="share" class="weui-popup__container" @click="share = false">
        <div class="weui-popup__overlay"></div>
        <div class="weui-popup__modal show-invite-popup">
          <img
            class="click-right-top"
            alt=""
            src="../assets/click-right-top.png"
          />
          <div class="survey-show-invite-message">
            <div class="margin-bottom-10 text-center">
              <i class="icon icon-public-shouzhi3 tip-hand"></i>
              点击右上角，分享至微信/朋友圈
            </div>
            <br />
            <div class="margin-bottom-10">分享问卷可获得两种奖励：</div>
            <br />
            <div class="remark">1、邀请奖励（多邀多得，上不封顶）</div>
            <div class="remark">被邀请人首次成功作答后，邀请奖励直接到账</div>
            <br />
            <div class="remark">
              <table class="table table-bordered">
                <tr class="header">
                  <td class="text-center">奖励</td>
                  <td class="text-center">成功作答人数</td>
                </tr>
                <tr>
                  <td class="text-center">1.00元/人</td>
                  <td class="text-center">500人及以下</td>
                </tr>
                <tr>
                  <td class="text-center">1.50元/人</td>
                  <td class="text-center">501~1000人</td>
                </tr>
                <tr>
                  <td class="text-center">2.00元/人</td>
                  <td class="text-center">1001人及以上</td>
                </tr>
              </table>
            </div>
            <div class="remark">个别低金额小问卷为固定奖励0.5元或以下/人</div>
            <br />
            <div class="remark">2、提成奖励</div>
            <div class="remark">
              您将半年内获得被邀请人作答（首次后）10%提成！
            </div>
          </div>
        </div>
      </div>
      <div v-if="share1" class="weui-popup__container" @click="share1 = false">
        <div class="weui-popup__overlay"></div>
        <div class="weui-popup__modal show-invite-popup">
          <img
            class="click-right-top"
            alt=""
            src="../assets/click-right-top.png"
          />
          <div class="survey-show-invite-message">
            <div class="margin-bottom-10 text-center">
              <i class="icon icon-public-shouzhi3 tip-hand"></i>
              点击右上角，在浏览器内开始答题。
            </div>
            <br />
            <div class="text-center">
              <div class="remark button" @click="checkNeedUserInput">继续在当前环境答题</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <weui-dialog :visible="dialogVisible" title="请输入相关信息" type="ios2" okText="进入问卷" @onCancel="confirmGo(false)" @onOk="confirmGo(true)">
      <input class="extInput" type="number" placeholder="请输入您的手机号/QQ号码" v-model="extInput" >
    </weui-dialog>
  </div>
</template>

<script>
import { getFinishedAList, getQInfo, launchAnswer } from '../api/index'
import { getParams } from '../utils/index'
import Swiper from "swiper";
import wxauth from '../mixin/wxauth'

export default {
  mixins: [wxauth],
  name: "surveyDetail",
  data() {
    return {
      share: false, // 邀请好友的弹窗
      share1: false, // 开始答题的弹窗
      dialogVisible: false, // 输入框弹窗 是否显示
      recordIdentifier: '',
      finishedList: [], // 完成问卷的人员列表
      answerGuide: '', // 这是答题指引
      successLimit: 0, // 成功次数限制
      needUserInput: 'N', // 是否需要用户输入 Y-是 N-不是
      extInput: '', // 用户输入信息
    };
  },
  created () {
    
    // this.doGetLaunchAnswer()
  },
  mounted() {
    // new Swiper(".swiper", {
    //   loop: true, //允许从第一张到最后一张，或者从最后一张到第一张  循环属性
    //   slidesPerView: 6, // 设置显示三张
    //   //centeredSlides : true,     //使当前图片居中显示
    //   freeMode: true, // 使幻灯片滑动时不止滑动一格，且不会自动贴合
    //   slidesPerGroup: 1, //定义1张图片为一组
    //   autoplay: true, //可选选项，自动滑动
    //   speed: 1000, //设置过度时间
    //   direction: "vertical",
    //   height: 300,
    // });
  },
  methods: {
    doWxAuthCb () {
      this.recordIdentifier = getParams('recordIdentifier')
      this.doGetFinishedAList()
      this.doGetQInfo()
    },
    // 获取完成答题的用户列表
    async doGetFinishedAList () {
      let params = {
        param: {
          recordIdentifier: this.recordIdentifier
        }
      }
      let res = {}
      try {
        res = await getFinishedAList(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          this.finishedList = res.result.finishedAnswerList
          this.$nextTick(() => {
            new Swiper(".swiper", {
              loop: true, //允许从第一张到最后一张，或者从最后一张到第一张  循环属性
              slidesPerView: 6, // 设置显示三张
              //centeredSlides : true,     //使当前图片居中显示
              freeMode: true, // 使幻灯片滑动时不止滑动一格，且不会自动贴合
              slidesPerGroup: 1, //定义1张图片为一组
              autoplay: true, //可选选项，自动滑动
              speed: 1000, //设置过度时间
              direction: "vertical",
              height: 300,
            });
          })
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    async doGetQInfo () {
      let params = {
        param: {
          recordIdentifier: this.recordIdentifier
        }
      }
      let res = {}
      try {
        res = await getQInfo(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          const result = res.result
          this.$toast.hide()
          this.answerGuide = result.answerGuide
          this.successLimit = result.successLimit
          this.needUserInput = result.needUserInput
          // this.finishedList = result.finishedAnswerList
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    async doGetLaunchAnswer () {
      let params = {
        param: {
          recordIdentifier: this.recordIdentifier,
          userInput: this.extInput
        }
      }
      let res = {}
      try {
        res = await launchAnswer(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          const url = res.result.answerLink
          if (url) {
            location.href = url
          }
        } else if (res.code === 'BIZ_1011') {
          this.$toast.hide()
          const _this = this
          this.$dialog.show({
            type: 'ios',
            title: null,
            content: '您暂未绑定手机号，请绑定后再进行相关操作。',
            showCancel: false,
            okText: '去绑定',
            onOk: function () {
              _this.$router.push({name: 'editMobile'})
            }
          })
        } else {
          this.$toast.hide()
          this.$toast.info(res.message)
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    // 开始答题按钮点击
    willAnswer () {
      const ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        this.share1 = true
      } else {
        this.checkNeedUserInput()
      }
    },
    checkNeedUserInput () {
      if (this.needUserInput === 'Y'){
        this.dialogVisible = true
      } else {
        this.goAnswer()
      }
    },
    // 开始答题按钮点击
    // willAnswer () {
    //   this.dialogVisible = true
    // },
    confirmGo (flag) {
      const extInput = this.extInput
      console.log('extInput', extInput)
      if (!flag) {
        this.dialogVisible = false
        this.extInput = ''
      } else {
        if (!extInput) {
          this.$toast.info('请输入您的手机号/QQ号码')
        } else {
          if (extInput.length > 200 || extInput.length < 3) {
            this.$toast.info('请输入正确的的手机号/QQ号码')
          } else {
            this.doGetLaunchAnswer()
            this.dialogVisible = false
            this.extInput = ''
          }
        }
      }
    },
    // 开始答题按钮点击
    goAnswer () {
      this.doGetLaunchAnswer()
      // if (localStorage.getItem('SURVEY_token')) {
      //   this.doGetLaunchAnswer()
      // } else {
      //   this.$dialog.show({
      //     type: 'ios',
      //     title: '弹窗标题',
      //     content: '弹窗内容，告知当前状态、信息和解决方法，描述文字尽量控制在三行内',
      //     cancelText: '辅助操作',
      //     okText: '主操作',
      //     showCancel: false,
      //     onOk: () => {
      //       console.log('点击主操作')
      //     }
      //   });
      // }
    },
    showDialog() {
      let content =
        '<div class="dialog text-left"><br><p class=" margin-bottom-10"><b>1、重试方法</b></p><p class="remark margin-bottom-10">第一步：关闭wifi，使用流量</p><p class="remark margin-bottom-10">第二步：打开飞行模式、等待5秒后关闭飞行模式、关闭wifi</p><p class="remark margin-bottom-10">第三步：在右上角“…”中选择“浏览器”或者“复制链接”到浏览器中作答（重复做的需要换个浏览器或者清理浏览器数据，cookies一定要清理）</p><br><p class=" margin-bottom-10"><b>2、重试奖励</b></p><p class="remark margin-bottom-10">1）成功：正常发放</p><p class="remark margin-bottom-10">2）失败：首次失败有奖励，第二次开始无奖励，防止恶意刷失败</p></div>';
      this.$dialog.show({
        title: "重试方法及规则",
        content: content,
        showCancel: false,
        okText: "确定",
      });
    },
  },
};
</script>

<style scoped>
.weui-tab {
  background: #597ef5;
}
.weui-popup__container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
}
.weui-popup__overlay {
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.show-invite-popup {
  position: absolute;
  top: 0;
  width: 100%;
}
.weui-btn.show-btn-invite{
  padding: unset;
}
.public-container-no-tarbar{
  margin: unset;
}
.remark.button{
  display: inline-block;
  border: 1px solid #ededed;
  padding: 2px 6px 1px 6px;
  border-radius: 4px;
}
.extInput{
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
/deep/ .answer-guide * {
  text-wrap: wrap !important;
}
</style>